html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

body {
  background-color: rgb(19, 19, 19);

  /*font-family: 'Roboto', cursive;*/
}

/*@import url('https://fonts.googleapis.com/css?family=Roboto');*/




.bg-header {
  background-color: rgb(19, 19, 19);
}

.text-about {
  font-size: 30px;
  color: rgb(189, 189, 189);
}
.align {
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding-right: 40px;
}

h1 {
  color: white;
}

p {
  color: rgb(90, 90, 90);
}

.card-color {
  background-color: rgb(32, 32, 32);
  padding: 0;
}
.color-t {
  color: rgb(146, 146, 146);
}

.color-g {
  width: 100%;
  height: 5px;
  align-items: center;
  background: rgb(36, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(36, 0, 0, 1) 0%,
    rgba(244, 0, 0, 1) 0%,
    rgba(27, 68, 195, 1) 100%
  );
}

.mt-top {
  margin-top: 270px;
}

.mt-top2 {
  margin-top: 100px;
}

.content-a {
  text-align: center;
}

.mar-b {
  margin-bottom: 70px;
  margin-top: 150px;
}

.about {
  font-size: 100px;
}

.name {
  font-size: 100px;
}

.sub {
  font-size: 70px;
}

.btn {
  /*background: linear-gradient(90deg, rgba(36,0,0,1) 0%, rgba(244,0,0,1) 0%, rgba(27,68,195,1) 100%);  */
  border: none;
}

.color-line-bottom {
  background-color: gray;
}

.margin-bottom-projects {
  margin-bottom: 150px;
}

@media (max-width: 768px) {
  .name {
    font-size: 70px;
  }

  .sub {
    font-size: 40px;
  }

  .desc {
    font-size: 20px;
  }

  .mar-b {
    margin-top: 10px;
  }

  .mt-top {
    margin-top: 60px;
  }

  .sub-s {
    font-size: 30px;
  }
}
/** grid **/
.grid-info {
  width: 100%;
  max-width: 1500px;
  height: 400px;
  display: grid;
  gap: 1.5vw;
  padding: 1vw;

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "hero hero aside2"
    "hero hero aside3";
}

.item-info {
  background-color: rgb(32, 32, 32);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grid-info .item-info:nth-child(1) {
  grid-area: hero;
}
.grid-info .item-info:nth-child(2) {
  grid-area: aside2;
}

.grid-info .item-info:nth-child(3) {
  grid-area: aside3;
}

.title-about {
  font-size: 80px;
}

.desc-about {
  font-size: 20px;
  width: 80%;
  color: white;
}

@media (max-width: 1024px) {
  .title-about {
    font-size: 45px;
  }
}

@media (max-width: 768px) {
  .grid-info {
    width: 100%;
    max-width: 1500px;
    height: 700px;
    display: grid;
    gap: 1.5vw;
    padding: 1vw;

    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "hero"
      "aside2"
      "aside3";
  }
}
