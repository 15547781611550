.grid {
  width: 100%;
  max-width: 1500px;
  height: 500px;
  display: grid;
  gap: 1.5vw;
  padding: 1vw;

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "hero aside2"
    "hero aside3";
}

.item {
  background-color: rgb(32, 32, 32);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grid .item:nth-child(1) {
  grid-area: hero;
}
.grid .item:nth-child(2) {
  grid-area: aside2;
}

.grid .item:nth-child(3) {
  grid-area: aside3;
}

.img1 {
  width: 400px;
  height: 400px;
  object-fit: fill;
}

.app_img1 {
}

.app1_title {
  font-size: 80px;
  background: linear-gradient(to right, orange, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content {
  width: 80%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.remove-bg {
}

.remove-title {
  font-size: 85px;
  background: linear-gradient(to right, rgb(149, 201, 250), white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.reality_title {
  font-size: 85px;
  background: linear-gradient(to right, rgb(149, 201, 250), white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1024px) {
  .app1_title {
    font-size: 45px;
    background: linear-gradient(to right, orange, white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .remove-title {
    font-size: 45px;
  }

  .reality_title {
    font-size: 45px;
  }
}

@media (max-width: 768px) {
  .grid {
    width: 100%;
    max-width: 1500px;
    height: 800px;
    display: grid;
    gap: 1.5vw;
    padding: 1vw;

    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "hero"
      "aside2"
      "aside3";
  }

  .img1 {
    width: 350px;
    height: 350px;
  }
}
